import { createSelector } from 'reselect';
import { ARCHIVE_PARAMS } from '@wix/communities-blog-client-common/dist/src/constants/archive';
import { getAppSettingsValue } from '../../common/selectors/app-settings-selectors';
import { buildArchiveLinks } from '../services/archive-links';
import { getPostStatsByDate } from '../../common/store/post-stats-by-date/post-stats-by-date-selectors';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import { EXPERIMENT_ARCHIVE_MONTHS_LIMIT } from '@wix/communities-blog-experiments';

const { showNewestPostsFirst, showPostCount, monthsDisplayLimit } = ARCHIVE_PARAMS;

export const getShowNewestPostsFirst = state =>
  getAppSettingsValue({
    state,
    key: showNewestPostsFirst.appSettingsPath,
    fallback: showNewestPostsFirst.defaultValue,
  });

export const getShowPostCount = state =>
  getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });

export const getMonthsDisplayLimit = state =>
  getAppSettingsValue({
    state,
    key: monthsDisplayLimit.appSettingsPath,
    fallback: monthsDisplayLimit.defaultValue,
  });

export const getArchiveLinks = createSelector(
  getPostStatsByDate,
  getShowNewestPostsFirst,
  getMonthsDisplayLimit,
  state => isExperimentEnabled(state, EXPERIMENT_ARCHIVE_MONTHS_LIMIT),

  (postStatsByDate, showNewestPostsFirst, monthsDisplayLimit, isArchiveMonthsLimitExperimentEnabled) =>
    buildArchiveLinks(
      postStatsByDate,
      showNewestPostsFirst,
      isArchiveMonthsLimitExperimentEnabled ? monthsDisplayLimit : 50,
    ),
);
